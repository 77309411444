import React from "react";
import VideoIcon from "../../assets/images/electronics_red.svg";
import VIconWhite from "../../assets/images/electronics_white.svg";
import AudioIcon from "../../assets/images/phone_consult_gray.svg"
import AudioIconWhite from "../../assets/images/phone_consult_white.svg";
import "../../assets/styles/style.css";
import jpg from "../../assets/images/jpeg.png";
import pdf from "../../assets/images/pdf.png";
import rotateLeft from "../../assets/images/rotate-left.png";
import rotateRight from "../../assets/images/rotate-right.png";
import prevImgIcon from "../../assets/images/previous-icon.svg";
import nextImgIcon from "../../assets/images/next-icon.svg";
import vitalGraphIco from "../../assets/images/ic-vitals.svg";
import mic_icotext from "../../assets/images/ic-mic.svg";
import $ from 'jquery';
import Popup from "reactjs-popup";
import HeaderComponent from "../header/headerComponent";
import HcSubHeaderComponent from "../sub-header-hc/hcsubHeaderComponent";
import VideoConferencing from "../video-conferencing/VideoConferencing";
import axios from "axios";
import {
  APPOINTMENT_DETAILS,
  SEPARATEUPLOADEDDOCS,
  GETGENDER,
  GETDOB,
  API_BASE_URL2,
  API_BASE_URL3,
  EXOTEL_URL,
  LOADER,
  LOADER_BLACK,
  BOOKINGTIME24TO12,
  HANDLEIMAGEROTATE,
  IMAGEPREVNEXTRESET,
  GETURLEXTENSION,
  STORE_VITALS_SAVE,
  NURSE_APPOINTMENT_DETAILS,
  NURSE_STORE_VITALS_SAVE,
  API_NURSE_BASE_URL,
  HOMECARE_SEND_PRESCRIPTION,
  HOMECARE_GRAPH,
  HOMECARE_CALL_RECORD_RATING,
  TEMPERATURE_TYPE_VALIDATE_FUNCTION,
  HANDLE_PATIENT_VITALS_COLORS,
  LOADER_RED,
  HOMECARE_CONSULT_ALERT
} from "../../utils/constant";
import ToastComponent from "../toast/toast";
import LS_SERVICE from "../../utils/localStorage";
import { withRouter } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import { Card, Button, Accordion } from "react-bootstrap";
import QbHelpers from "../../helpers/QbHelpers";
import BeautyStars from 'beauty-stars';
import Chart from '../docConsult/hcChart'
import Moment from "react-moment";
import moment from 'moment';
import { messageService, callService } from '../../utils/data-sharing';
import QbVideoConferencing from "../video-conferencing/QbVideoConferencing";

let ENDCALL = "";

class HcConsult extends React.Component {
  state = {
    callPatientBtn: true, // true means call btn disabled
    callNotificationMsg: "",
    patient: {
      name: '',
      number: null,
      id: null,
      dob: '',
      uhid: null,
      gender: '',
      booking_time: null,
    },
    appointment_status: null,
    appointment_details: [],
    booking_id: null,
    uploads: [],
    accordion_open: 0,
    patient_vitals: [],
    modal: false,
    dataFile: "",
    modalClass: "modal-imgdocs",
    documentIndex: 0,
    documentCount: 0,
    documentType: "",
    allDocuments: [],
    canEndCall: false,
    hungupExistingCall: false,
    completeAppointmentBtnValid: true,
    openhandleCompleteAppointmentButtonPop: false,
    openhandleCompleteAppointmentButtonPopLoader: false,
    openAudioCallPop: false,
    toastSuccessClass: "bg-danger",
    toast: false,
    toastmsg: "",
    QBEndCall: true,
    handleCallFeedbackPopup: false,
    doctor_rating: 5,
    doctor_remarks: "",
    callInitialized: false,
    prescriptionChecklistPop: false,
    chartsPop: false,
    chartData: {},
    chartsTab: 0,
    homeCare: null,
    notes_nurse_prev: null,
    notes_nurse: "",
    notes_doctor_prev: null,
    notes_doctor: "",
    medicines_tests_prev: null,
    medicines_tests: "",
    is_doctor: null,
    defaultAccordianOpen: null,
    addvital: [],
    is_adding_vital: false,
    pageDataLoaded: false,
    subHeaderData: {},
    send_progress_notes_nurse: 0,
    send_progress_notes_doctor: 0,
    send_patient_vitals: 0,
    send_notes_for_doctor: 0,
    send_medicine_test: 0,
    added_progress_data: false,
    is_called_patient: 0,
    date_range_graph: false
  };

  constructor(props) {
    super(props);
    this.updatePage = this.updatePage.bind(this);
    if (props.location.state === undefined) {
      props.history.push("/patient-list");
      return;
    }
  }

  componentWillReceiveProps(props) {

    const { state } = props.location;

    this.setState({
      pageDataLoaded: false,
      appointment_status: state.booking_status_code,
      booking_id: state.booking_id,
      homeCare: state.homeCare
    }, () => this.handleAppointmentDetails())
  }

  componentWillMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    const { state } = this.props.location;

    this.setState({
      appointment_status: state.booking_status_code,
      booking_id: state.booking_id,
      is_called_patient: state.call_today,
      homeCare: state.homeCare,
      is_doctor: LS_SERVICE.get('is_doctor'),
      defaultAccordianOpen: LS_SERVICE.get('is_doctor') ? "0" : "2",
    })
  }

  componentDidMount() {
    this.handleAppointmentDetails();
  }

  updatePage() {
    console.log('OUTOUTOUT');
    this.forceUpdate();
  }

  handleAppointmentDetails = () => {
    let { is_doctor, booking_id, is_called_patient } = this.state;

    is_called_patient = is_called_patient !== undefined ? is_called_patient : 0

    let URL = "";

    if (is_doctor) {
      URL = `${APPOINTMENT_DETAILS({ booking_id, is_homecare: 1, is_called_patient })}`;
    } else {
      URL = `${NURSE_APPOINTMENT_DETAILS({ booking_id, is_homecare: 1, is_called_patient })}`;
    }

    axios
      .get(URL)
      .then((Response) => {
        const data = Response.data;
        let name = `${data.appointment.patient.first_name} ${data.appointment.patient.last_name !== null
            ? data.appointment.patient.last_name
            : ""
          }`;

        let formDataHC = [];
        if (LS_SERVICE.has('formDataHC')) {
          formDataHC = LS_SERVICE.get('formDataHC');
        }

        let medicines_tests = '';
        if (formDataHC.find(e => e.id === data.appointment.id) !== undefined) {
          formDataHC.forEach(e => {
            if (e.id === data.appointment.id) {
              medicines_tests = e['medicines_tests'];
            }
          })
        }

        this.setState({
          appointment_status: data.appointment.booking_status_code,
          appointment_details: data.appointment,
          uploads: SEPARATEUPLOADEDDOCS(
            data.appointment.patient_docments
          ),
          patient_vitals: data.appointment.patient_vitals !== null ? data.appointment.patient_vitals : [],
          notes_doctor_prev: data.appointment.notes_by_doctor !== null ? data.appointment.notes_by_doctor : {
            count: 0,
            date: "",
            notes: "",
            notes_by: "",
            notes_by_name: ""
          },
          notes_nurse_prev: data.appointment.notes_by_nurse !== null ? data.appointment.notes_by_nurse : {
            count: 0,
            date: "",
            notes: "",
            notes_by: "",
            notes_by_name: ""
          },
          medicines_tests_prev: data.appointment.medicine_test_advice.length === 0 ? {
            count: 0,
            date: "",
            notes: "",
            notes_by: "",
            notes_by_name: ""
          } : data.appointment.medicine_test_advice,
          // diagnosis_notes: diagnosis_notes !== '' ? diagnosis_notes : data.appointment.diagnosis_notes ?? "",
          medicines_tests: medicines_tests !== '' ? medicines_tests : "",
          patient: {
            name: name,
            number: data.appointment.patient.mobile_no,
            id: data.appointment.patient.id,
            dob: GETDOB(data.appointment.patient.dob),
            uhid: data.appointment.patient.uhid,
            booking_time: data.appointment.start_time,
            gender: GETGENDER(data.appointment.patient.gender),
          }
        }, () => this.setState({
          subHeaderData: {
            dataLoaded: true,
            is_BackPatientButtonVisible: false,
            name: this.state.patient.name,
            gender: this.state.patient.gender,
            dob: this.state.patient.dob,
            uhid: this.state.patient.uhid,
            is_homecare: true,
            pageTitle: `${this.state.patient.name} ${this.state.patient.dob !== ""
                ? this.state.patient.dob + " Yrs,"
                : ""
              } ${this.state.patient.gender}`,
            subPageTitle: this.state.patient.uhid !== "" ? this.state.patient.uhid : '',
            subPageTitle2: 'Appointment Time: ' + BOOKINGTIME24TO12(this.state.patient.booking_time),
            patient_id: this.state.patient.id,
            booking_id: this.state.appointment_details.id,
            alert: {
              alerts: [],
              notes: [],
              boooking_id: this.state.appointment_details.patient_alert.boooking_id,
              status: this.state.appointment_details.patient_alert.status
            },
            package_data: this.state.appointment_details.test
          },
          pageDataLoaded: true
        }, () => this.checkAppointmentForm())
        )
        // }, () => this.handleAlertDataLoad())
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  // handleAlertDataLoad = () => {

  //   const { is_doctor, booking_id } = this.state;

  //   axios
  //     .get(HOMECARE_CONSULT_ALERT({ is_doctor, booking_id }))
  //     .then((Response) => {
  //       const data = Response.data;

  //       this.setState({
  //         subHeaderData: {
  //           dataLoaded: true,
  //           is_BackPatientButtonVisible: false,
  //           name: this.state.patient.name,
  //           gender: this.state.patient.gender,
  //           dob: this.state.patient.dob,
  //           uhid: this.state.patient.uhid,
  //           is_homecare: true,
  //           pageTitle: `${this.state.patient.name} ${
  //             this.state.patient.dob !== ""
  //               ? this.state.patient.dob + " Yrs,"
  //               : ""
  //             } ${this.state.patient.gender}`,
  //           subPageTitle: this.state.patient.uhid !== "" ? this.state.patient.uhid : '',
  //           subPageTitle2: 'Appointment Time: ' + BOOKINGTIME24TO12(this.state.patient.booking_time),
  //           patient_id: this.state.patient.id,
  //           booking_id: this.state.appointment_details.id,
  //           alert: {
  //             alerts: [],
  //             notes: [],
  //             boooking_id: this.state.appointment_details.patient_alert.boooking_id,
  //             status: this.state.appointment_details.patient_alert.status
  //           }
  //         },
  //         pageDataLoaded: true
  //       })
  //     })
  //     .catch((error) => {
  //       if (error && error.response && error.response.status === 401) {
  //         LS_SERVICE.clear();
  //         this.props.history.push("/");
  //       }
  //     });
  // }

  documentToggle = (e, files, isDoc = false) => {
    if (files !== undefined) {
      if (isDoc) {
        this.setState({
          modalClass: "modal-imgdocs modal-docs",
          documentType: 'application/pdf',
        });
      } else {
        this.setState({
          modalClass: "modal-imgdocs",
          documentType: '',
        });
      }
      if (e !== null) {
        e.preventDefault();
        this.setState({
          documentIndex: $(e.currentTarget).index(),
          documentCount: $(e.currentTarget).siblings().length
        }, () => {
          this.setState({
            showArrow: this.state.documentCount > 1 ? true : false,
          })
        })

        this.setState({
          allDocuments: files,
          dataFile: files[$(e.currentTarget).index()],
        });
      } else {
        this.setState({
          dataFile: files,
        })
      }
    }

    this.setState({
      modal: !this.state.modal,
    })
  };

  callUser() {
    callService.init({ start: true, is_homecarecall: true });
    // this.handleCallRatings(null, { status: 0 });
    // this.handleCallRatings(null, { status: 0, callStatus: () => callService.init({ start: true }) });
  }

  endCall() {
    callService.init({ end: true, is_homecarecall: true });
    // this.handleCallRatings(null, { status: 1 });
  }

  receiveInParent = (callInfo) => {
    if (typeof callInfo !== "undefined") {
      this.setState(
        {
          callPatientBtn: callInfo.callPatientBtn,
          callNotificationMsg: callInfo.callNotificationMsg,
          canEndCall: callInfo.canEndCall,
          hungupExistingCall: callInfo.hungupExistingCall || false,
        },
        // () => {
        //   this.checkAppointmentForm();
        // }
      );
    }
  }

  checkAppointmentForm() {
    if (this.state.medicines_tests.length > 0 || this.state.added_progress_data) {
      this.setState({
        completeAppointmentBtnValid: false,
      });
    } else {
      this.setState({
        completeAppointmentBtnValid: true,
      });
    }
  }

  handleInputChange = (event) => {

    const target = event.target;
    const { medicines_tests } = this.state;

    const { name, value } = target;

    let frmDataHC = {
      id: this.state.appointment_details.id,
      // diagnosis_notes,
      medicines_tests,
      // notes_doctor,
      // notes_nurse
    }

    this.setState({ [name]: value },
      () => {
        // frmData.diagnosis_notes = diagnosis_notes;
        frmDataHC.medicines_tests = this.state.medicines_tests;
        // frmData.notes_nurse = notes_nurse;
        // frmData.notes_doctor = notes_doctor;
        this.formDataEntry(frmDataHC);
      });
  }

  formDataEntry(data) {

    const { id, medicines_tests } = data;

    let formDataHC = [];
    if (LS_SERVICE.has('formDataHC')) {
      formDataHC = LS_SERVICE.get('formDataHC');
    } else {
      formDataHC = [{
        id,
        // diagnosis_notes,
        medicines_tests
      }];
      LS_SERVICE.set('formDataHC', formDataHC)
    }

    if (formDataHC.find((e) => e.id === this.state.appointment_details.id) !== undefined) {
      formDataHC.forEach(e => {
        if (e.id === this.state.appointment_details.id) {
          // e['diagnosis_notes'] = data.diagnosis_notes;
          e['medicines_tests'] = data.medicines_tests;
        }
      })
    } else {
      formDataHC = [...formDataHC, { id, medicines_tests }]
    }
    LS_SERVICE.set('formDataHC', formDataHC);
    this.checkAppointmentForm();
  }

  completeAppointment(e, booking_id) {
    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').toggleClass('loader-active');
    }

    if (ENDCALL !== "") {
      clearInterval(ENDCALL);
      ENDCALL = "";
    }

    let formDataHC = [];
    if (LS_SERVICE.has('formDataHC')) {
      formDataHC = LS_SERVICE.get('formDataHC');
    }

    if (formDataHC.find(e => e.id === booking_id) !== undefined) {
      formDataHC = formDataHC.filter((e) => e.id !== booking_id);
      LS_SERVICE.set('formDataHC', formDataHC);
    }

    const { medicines_tests, send_medicine_test, send_progress_notes_doctor, send_progress_notes_nurse,
      send_patient_vitals, send_notes_for_doctor, is_doctor } = this.state;

    const PARAMS = {
      booking_id,
      medicine_test_advice: medicines_tests,
      has_to_send_medicine_advice: send_medicine_test,
      has_to_send_progress_notes: send_progress_notes_doctor,
      has_to_send_patient_vital: send_patient_vitals,
      has_to_send_notes_for_doctor: send_notes_for_doctor,
      has_to_send_progress_nurse_notes: send_progress_notes_nurse
    }

    // if (!is_doctor) {
    //   PARAMS.has_to_send_progress_nurse_notes = send_progress_notes_nurse;
    // }

    axios
      .post(HOMECARE_SEND_PRESCRIPTION({ is_doctor }), PARAMS)
      .then((Response) => {
        if (elem !== null)
          $(elem).find('span.loader-span').toggleClass('loader-active');
        this.setState({
          openhandleCompleteAppointmentButtonPop: false,
        }, () => {
          if (this.state.callInitialized) {
            this.handleBeautyPopup();
          } else {
            this.resetToastState(() => this.setState({
              handleCallFeedbackPopup: false,
              toastSuccessClass: "bg-success",
              toast: true,
              toastmsg: "Prescription Sent Successfully!",
            }))
          }
        });

        if (is_doctor && medicines_tests !== "") {
          this.handleNotesTestsClick(null, 'storetestmedicineadvice');
        }

      })
      .catch((error) => {
        if (elem !== null)
          $(elem).find('span.loader-span').toggleClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  endCallAndCompleteAppointment(e, booking_id) {
    $(e.target).find('span.loader-span').addClass('loader-active');
    this.endCall();
    ENDCALL = setInterval(() => {
      if (!QbHelpers.isCallActive) {
        this.completeAppointment(null, booking_id);
      }
    }, 100);
  }

  openPrescriptionDoc(booking_id) {
    axios.get(API_BASE_URL2 + "bookings/" + booking_id + "/prescriptions?mode=web")
      .then((Response) => {
        var pdfUrl = Response.data;
        this.documentToggle(null, pdfUrl, true)
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleHomeCareSPCheckBox = e => {
    this.setState({
      prescriptionChecklistPop: true,
    })
  }

  handleCompleteAppointmentClick = () => {
    this.setState({
      prescriptionChecklistPop: false,
      openhandleCompleteAppointmentButtonPop: true
    }, () => this.setState({
      QBEndCall: QbHelpers.isCallActive ? false : true
    }))
  }

  handleCompleteAppointmentButton = (value) => {
    this.setState({
      openhandleCompleteAppointmentButtonPop: value
    })
  }

  handleAudioCallPop = (value) => {
    this.setState({
      openAudioCallPop: value
    })
  }

  handleAUdioCallApi = (e) => {
    const elem = e.target;
    $(elem).find('span.loader-span').toggleClass('loader-active');
    const { id } = this.state.appointment_details;
    const { is_homecare } = this.state.subHeaderData;

    axios.post(EXOTEL_URL, { booking_id: id, is_home_care: is_homecare ? 1 : 0 })
      .then((response) => {
        $(elem).find('span.loader-span').toggleClass('loader-active');
        this.setState({
          openAudioCallPop: false
        })
      })
      .catch((error) => {
        console.log(error);
        $(elem).find('span.loader-span').toggleClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleNextPrev = (e) => {
    e.preventDefault();
    const { documentIndex, allDocuments, documentCount } = this.state;
    let docIndex = documentIndex;
    const targetName = e.currentTarget;
    if (targetName.getAttribute("name") === "image-left") {
      if (docIndex > 0) {
        this.setState({
          documentIndex: documentIndex - 1,
          documentType: GETURLEXTENSION(allDocuments[docIndex - 1]),
          dataFile: allDocuments[docIndex - 1]
        }, () => {
          if (this.state.documentType === "application/pdf") {
            this.setState({
              modalClass: "modal-imgdocs modal-docs",
            })
          } else {
            this.setState({
              modalClass: "modal-imgdocs",
            })
          }
          IMAGEPREVNEXTRESET(this.state.dataFile);
        })
      }
    } else {
      if (docIndex < documentCount) {
        this.setState({
          documentIndex: documentIndex + 1,
          documentType: GETURLEXTENSION(allDocuments[docIndex + 1]),
          dataFile: allDocuments[docIndex + 1]
        }, () => {
          if (this.state.documentType === "application/pdf") {
            this.setState({
              modalClass: "modal-imgdocs modal-docs",
            })
          } else {
            this.setState({
              modalClass: "modal-imgdocs",
            })
          }
          IMAGEPREVNEXTRESET(this.state.dataFile);
        })
      }
    }
  };

  handleDontRate = _ => {
    this.handleCallRatings(null, { status: 2 });
  };

  handleBeautyPopup = _ => {
    this.setState({
      handleCallFeedbackPopup: !this.state.handleCallFeedbackPopup
    });
  }

  handleCallRatings = (e, data) => {

    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').addClass('loader-active');
    }

    let { doctor_rating, doctor_remarks, is_doctor } = this.state;

    doctor_rating = data.status === 2 ? doctor_rating : 0;

    axios
      .post(HOMECARE_CALL_RECORD_RATING({ is_doctor }), {
        booking_id: this.state.appointment_details.id,
        doctor_remarks,
        doctor_rating,
        state: data.status // 2 for feedback Submit 1 for Call end and 0 for call start
      })
      .then((Response) => {
        if (data.status === 2) {
          if (elem !== null)
            $(elem).find('span.loader-span').removeClass('loader-active');

          this.resetToastState(() => this.setState({
            toastSuccessClass: "bg-success",
            toast: true,
            toastmsg: "Prescription Sent Successfully",
          }));

          this.setState({
            handleCallFeedbackPopup: false,
            callInitialized: false
          })
          // })
        } else {
          this.setState({
            callInitialized: true,
            handleCallFeedbackPopup: false
          }, () => {
            if (data.callStatus) {
              data.callStatus();
              if (data.status === 0)
                LS_SERVICE.set('is_homecarecall', true);
            }
            // else if (!this.state.canEndCall) {
            //   this.props.history.push("/patient-lists");
            // }
          })
        }
      })
      .catch((error) => {
        if (elem !== null)
          $(elem).find('span.loader-span').removeClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleBeautyPopupSubmit = (e) => {
    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').toggleClass('loader-active');
    }

    const { doctor_rating } = this.state;

    if (doctor_rating === 0) {
      $(elem).find('span.loader-span').toggleClass('loader-active');
      $('.error-beautystars').removeClass('hidden');
      return;
    }

    this.handleCallRatings(e, { status: 2 });
  }

  setDoctorRating = (doctor_rating) => {
    this.setState({
      doctor_rating
    }, () => {
      if (this.state.doctor_rating > 0) {
        $('.error-beautystars').addClass('hidden');
      }
    })
  }

  handleAddVitalsClick = _ => {

    let { addvital, patient_vitals } = this.state;

    if (patient_vitals.length > 2)
      document.querySelector('.patient-vitalslist li:last-child').classList.add("hidden");

    addvital = [...addvital, {
      temperature: "",
      spo3: "",
      systolic: "",
      diastolic: "",
      pulse: "",
      rbs: "",
    }]

    this.setState({
      addvital,
      is_adding_vital: true
    })
  }

  handleVitalInputChange = (e, i) => {
    let { addvital } = this.state;

    const { name, value } = e.target;

    addvital[i][name] = e.target.value;

    this.setState({
      addvital
    })
  }

  resetToastState = (cb) => {
    this.setState({
      toastSuccessClass: "bg-danger",
      toast: false,
      toastmsg: "",
    }, () => cb());
  };

  handlePatientVitals = () => {

    const { subHeaderData } = this.state;

    subHeaderData.is_BackPatientButtonVisible = true;

    this.props.history.push({
      pathname: "/patient-vitals",
      state: {
        booking_id: this.state.booking_id,
        subHeaderData
      }
    })
  }

  handleProgressNotesDoc = (progress_button_from = "") => {

    const { subHeaderData, booking_id } = this.state;

    subHeaderData.is_BackPatientButtonVisible = true;
    subHeaderData.is_ProgressButtonActive = true;

    this.props.history.push({
      pathname: "/progress-notes",
      state: {
        booking_id: booking_id,
        active_tab: progress_button_from,
        subHeaderData
      }
    })
  }

  handleNotesTestsClick = (e, type = "") => {

    let elem = null;
    if (e !== null) {
      e.preventDefault();
      elem = e.currentTarget;
      $(elem).find('span.loader-span').toggleClass('loader-active');
    }

    let dataType = "";

    if (elem !== null)
      dataType = elem.dataset.notes;
    else
      dataType = type;

    const { notes_doctor, notes_nurse, medicines_tests, is_doctor } = this.state;

    const notesData = is_doctor ? notes_doctor : notes_nurse;

    const PARAMS = {
      booking_id: this.state.booking_id,
    }

    if (dataType === "storetestnotes") {
      if (notesData === "") {
        this.resetToastState(() => this.setState({
          toastSuccessClass: "bg-danger",
          toast: true,
          toastmsg: "Please enter some inputs before saving",
        }));
        if (elem !== null)
          $(elem).find('span.loader-span').toggleClass('loader-active');
        return;
      }
      PARAMS.notes_by = is_doctor ? 3 : 2; // 3 if doctor logged in 2 when nurse and nothing for medicine and test adviced
      PARAMS.notes_to_patient = notesData;
    } else {
      if (medicines_tests === "") {
        this.resetToastState(() => this.setState({
          toastSuccessClass: "bg-danger",
          toast: true,
          toastmsg: "Please enter some inputs before saving",
        }));
        if (elem !== null)
          $(elem).find('span.loader-span').toggleClass('loader-active');
        return;
      }
      PARAMS.medicine_advice = medicines_tests;
    }

    let URL = "";

    if (is_doctor) {
      URL = `${API_BASE_URL3}`;
    } else {
      URL = `${API_NURSE_BASE_URL}`;
    }

    axios
      .post(URL + dataType, PARAMS)
      .then((Response) => {
        const data = Response.data.data;
        const { notes_doctor_prev, notes_nurse_prev, medicines_tests_prev } = this.state;

        if (dataType === "storetestnotes") {
          if (is_doctor) {
            Object.assign(notes_doctor_prev, data);
          } else {
            Object.assign(notes_nurse_prev, data);
          }
        } else {
          Object.assign(medicines_tests_prev, data);
        }

        this.setState({
          notes_doctor_prev,
          notes_nurse_prev,
          medicines_tests_prev,
          added_progress_data: true,
          notes_doctor: "",
          notes_nurse: "",
          medicines_tests: ""
        }, () => this.checkAppointmentForm())

        if (elem !== null)
          $(elem).find('span.loader-span').toggleClass('loader-active');
      })
      .catch((error) => {
        if (elem !== null)
          $(elem).find('span.loader-span').toggleClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleVitalCancelSave = type => {

    if (type === "cancel") {

      document.querySelector('.patient-vitalslist li:last-child').classList.remove("hidden");
      this.setState({
        addvital: [],
        is_adding_vital: false
      })

    } else {
      const { addvital, is_doctor } = this.state;

      if (Object.values(addvital[0]).every(e => e === "")) {
        this.resetToastState(() => this.setState({
          toastSuccessClass: "bg-danger",
          toast: true,
          toastmsg: "Please enter some inputs before saving",
        }));
        return;
      }

      // REMOVED VALIDATIONS AS IT WILL BE HANDLED IN BACKEND

      // if(addvital[0].bp !== "") {
      //   if (addvital[0].bp.indexOf("/") === -1) {
      //     this.resetToastState(() => this.setState({
      //       toastSuccessClass: "bg-danger",
      //       toast: true,
      //       toastmsg: "Please add Forward-Slash between Systolic and Diastolic values",
      //     }));
      //     return;
      //   } else {
      //     const vitalArr = addvital[0].bp.split("/");
      //     const vitalValid = [];
      //     vitalArr.forEach(e => {
      //       if (e.indexOf("/") === -1) {
      //         if (e !== "") {
      //           vitalValid.push(true);
      //         } else {
      //           vitalValid.push(false);
      //         }
      //       }
      //     })
      //     if (!vitalValid.every(e => e === true)) {
      //       this.resetToastState(() => this.setState({
      //         toastSuccessClass: "bg-danger",
      //         toast: true,
      //         toastmsg: "Please add value before and after Forward-Slash",
      //       }));
      //       return;
      //     }
      //   }
      // }

      addvital[0].booking_id = this.state.booking_id;
      addvital[0].vital_by = is_doctor ? 3 : 2;
      addvital[0].temperature_type = 0; // 0 is Faranhite
      addvital[0].bp = addvital[0].systolic || addvital[0].diastolic ? addvital[0].systolic + '/' + addvital[0].diastolic : "";

      let URL = "";

      if (is_doctor) {
        URL = STORE_VITALS_SAVE;
      } else {
        URL = NURSE_STORE_VITALS_SAVE;
      }

      axios
        .post(URL, addvital[0])
        .then((Response) => {
          const data = Response.data.data;
          document.querySelector('.patient-vitalslist li:last-child').classList.remove("hidden");
          this.setState({
            patient_vitals: data,
            addvital: [],
            is_adding_vital: false
          })
        })
        .catch((error) => {
          if (error && error.response && error.response.data.errors.length > 0) {
            this.resetToastState(() => this.setState({
              handleCallFeedbackPopup: false,
              toastSuccessClass: "bg-danger",
              toast: true,
              toastmsg: error.response.data.errors[0].message,
            }))
          }
          if (error && error.response && error.response.status === 401) {
            LS_SERVICE.clear();
            this.props.history.push("/");
          }
        });
    }
  }

  handlePrescriptionCheckbox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked ? 1 : 0
    })
  }

  handleGraphClick = (e, type = 0) => {

    this.setState({
      chartsTab: type,
      chartsPop: true,
      chartDataLoaded: false
    })

    const { date_range_graph } = this.state;

    if (date_range_graph)
      this.handleDateRangeClick();

    e.preventDefault();

    const { booking_id } = this.state;

    axios
      .get(HOMECARE_GRAPH({ booking_id, type }))
      .then((Response) => {
        this.setState({
          chartDataLoaded: true,
          chartData: Response.data.records
        })
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleDateRangeClick = () => {
    this.setState(
      prev => ({
        date_range_graph: !prev.date_range_graph
      })
    )
  }

  render() {

    const { pageDataLoaded, subHeaderData, date_range_graph, is_doctor } = this.state;

    return (
      <>
        <HeaderComponent />
        <HcSubHeaderComponent data={pageDataLoaded ? subHeaderData : ""} />
        <div className={`container-fluid custom-container mb-3 ${pageDataLoaded ? "visible" : "hidden"}`}>
          <div className="cf_wrapper hc_wrapper d-flex flex-column">
            <div className="row mt-3">
              <div className="col-7">
                <div
                  className="video-btn"
                  id="patient_call_widget"
                  data-pid={this.state.patient.id}
                >
                  {this.state.canEndCall ? (
                    <button
                      title="Cancel Call"
                      type="button"
                      className="img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                      onClick={() => this.endCall()}
                    >
                      <img
                        src={VideoIcon}
                        alt="video icon"
                        className="danger mr-2"
                      />
                      <img
                        src={VIconWhite}
                        alt="video icon"
                        className="danger mr-2"
                      />
                      END CALL
                    </button>
                  ) : (
                      <button
                        disabled={this.state.callPatientBtn}
                        title={
                          this.state.callPatientBtn
                            ? "Cannot call patient"
                            : "Call Patient"
                        }
                        type="button"
                        className="img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                        onClick={() => this.callUser()}
                      >
                        <img
                          src={VideoIcon}
                          alt="video icon"
                          className="danger mr-2"
                        />
                        <img
                          src={VIconWhite}
                          alt="video icon"
                          className="danger mr-2"
                        />
                        CALL PATIENT
                      </button>
                    )}

                  <span className="call-message">
                    {this.state.callNotificationMsg}

                    {this.state.hungupExistingCall ? (
                      <button
                        title="Cancel Call"
                        type="button"
                        className="ml-3 img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                        onClick={() => this.endCall()}
                      >
                        <img
                          src={VideoIcon}
                          alt="video icon"
                          className="danger mr-2"
                        />
                        <img
                          src={VIconWhite}
                          alt="video icon"
                          className="danger mr-2"
                        />
                        END ONGOING CALL
                      </button>
                    ) : null}
                  </span>
                </div>
              </div>

              <div className="col">
                <div className="video-btn audio-btn">
                  <button title="Call Patient" type="button"
                    className="img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                    onClick={() => this.handleAudioCallPop(true)}
                  >
                    <img src={AudioIcon} alt="video icon" className="danger mr-2" /><img src={AudioIconWhite} alt="video icon" className="danger mr-2" />
                  AUDIO CALL TO PATIENT
              </button>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <div className="row video-conferencing-column">
                  {this.state.patient.number != null ? (
                    <QbVideoConferencing
                      // ref="vcref"
                      patient={this.state.patient}
                      booking_id={this.props.location.state.booking_id}
                      location={this.props.location}
                      onChange={this.receiveInParent}
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-9 notes-section patient-vitalsection">
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex flex-column notes-conent">
                          <div className="bg-light doctor-notes px-3">
                            <label className="label1 mt-2">NOTES FROM PATIENT</label>
                            <p
                              className={`doc-notes-text float-left ${this.state.appointment_details.patient_note_doctor ===
                                  null
                                  ? "no-notes"
                                  : ""
                                }`}
                            >
                              {this.state.appointment_details.patient_note_doctor ||
                                "No Notes"}
                            </p>
                          </div>

                          <div className="bg-light doctor-notes doctor-documents px-3 pb-2">
                            <label className="label1 mt-2">DOCUMENTS UPLOADED</label>
                            <div className="d-flex justify-content-start align-items-center">
                              {(this.state.uploads &&
                                this.state.uploads.fileDoc &&
                                this.state.uploads.fileDoc.length > 0) ||
                                (this.state.uploads &&
                                  this.state.uploads.fileImg &&
                                  this.state.uploads.fileImg.length > 0) ? (
                                  <div className="">
                                    {this.state.uploads &&
                                      this.state.uploads.fileImg &&
                                      this.state.uploads.fileImg.length > 0
                                      ? this.state.uploads.fileImg.map((file, i) => {
                                        return (
                                          <button
                                            className="btn btn-outline-light p-0 mr-1"
                                            key={i}
                                            onClick={(e) => this.documentToggle(e, [...this.state.uploads.fileImg, ...this.state.uploads.fileDoc])}
                                          >
                                            <img
                                              src={jpg}
                                              alt="pdf icon"
                                              className="pdf"
                                            ></img>
                                          </button>
                                        );
                                      })
                                      : ""}

                                    {this.state.uploads &&
                                      this.state.uploads.fileDoc &&
                                      this.state.uploads.fileDoc.length > 0
                                      ? this.state.uploads.fileDoc.map((file, i) => {
                                        return (
                                          <button
                                            className="btn btn-outline-light p-0 mr-1"
                                            key={i}
                                            onClick={(e) => this.documentToggle(e, [...this.state.uploads.fileImg, ...this.state.uploads.fileDoc], true)}
                                          >
                                            <img
                                              src={pdf}
                                              alt="pdf icon"
                                              className="pdf"
                                            ></img>
                                          </button>
                                        );
                                      })
                                      : ""}
                                  </div>
                                ) : (<p className="doc-notes-text no-notes m-0">
                                  Nil
                                </p>)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="bg-light doctor-notes patient-vitals px-3">
                          <label className="label1 mt-0 px-3">
                            PATIENT VITALS
                            <span className="vital-span" onClick={this.handleGraphClick} >
                              <img src={vitalGraphIco} alt="Graph Icon" />
                            </span>
                            <span className="view-all" onClick={this.handlePatientVitals}>View All</span>
                          </label>
                          <div
                            className={`doc-notes-text float-left ${pageDataLoaded && this.state.patient_vitals.length === 0
                                ? "no-notes px-3 py-2"
                                : ""
                              }`}
                          >
                            {(pageDataLoaded && this.state.patient_vitals.length > 0) || this.state.addvital.length > 0 ? (
                              <ul className="list-unstyled past-prescriptionlist patient-vitalslist m-0">
                                <li className="vital-label">
                                  <span className="pv-title">&nbsp;</span>
                                  <span className="pv-common">
                                    Temperature
                                </span>
                                  <span className="pv-common">
                                    SpO2
                                </span>
                                  <span className="pv-common">
                                    BP
                                </span>
                                  <span className="pv-common">
                                    Pulse
                                </span>
                                  <span className="pv-common mb-1">
                                    RBS
                                </span>
                                  <span className="pv-common pvc-fixed">
                                    Recorded by
                                </span>
                                </li>
                                {this.state.addvital.map((data, i) => (
                                  <li className="nurse" key={i}>
                                    <span className="pv-title">
                                      <Moment format="D MMM" parse="YYYY-MM-DD HH:mm">
                                        {new Date()}
                                      </Moment>{" "}
                                      <br />
                                      <Moment format="h:mm A" parse="YYYY-MM-DD HH:mm">
                                        {new Date()}
                                      </Moment>
                                    </span>
                                    <span className="pv-common">
                                      <input value={data.temperature} placeholder="00.00" autoComplete="off" name="temperature"
                                        onChange={(e) => this.handleVitalInputChange(e, i)} /> F
                                    </span>
                                    <span className="pv-common">
                                      <input value={data.spo3} placeholder="00" autoComplete="off" name="spo3"
                                        onChange={(e) => this.handleVitalInputChange(e, i)} /> %
                                    </span>
                                    {/* <span className="pv-common pt-1">
                                      <span className="bp-inputs">
                                        <input className="border-0 w-25 p-0" value={data.systolic} placeholder="00" autoComplete="off" name="systolic"
                                          onChange={(e) => this.handleVitalInputChange(e, i)} /> /
                                        <input className="border-0 w-25 p-0" value={data.diastolic} placeholder="00" autoComplete="off" name="diastolic"
                                          onChange={(e) => this.handleVitalInputChange(e, i)} />
                                      </span> mmHg
                                    </span> */}
                                    <span className="pv-common">
                                      <span className="bp-2 mr-1">
                                        <input className="border-0 w-25 p-0" value={data.systolic} placeholder="00" autoComplete="off" name="systolic"
                                          onChange={(e) => this.handleVitalInputChange(e, i)} /> /
                                        <input className="border-0 w-25 p-0" value={data.diastolic} placeholder="00" autoComplete="off" name="diastolic"
                                          onChange={(e) => this.handleVitalInputChange(e, i)} />
                                      </span>
                                      mmHg
                                    </span>
                                    <span className="pv-common">
                                      <input value={data.pulse} placeholder="00" autoComplete="off" name="pulse"
                                        onChange={(e) => this.handleVitalInputChange(e, i)} /> bpm
                                    </span>
                                    <span className="pv-common mb-1">
                                      <input value={data.rbs} placeholder="00" autoComplete="off" name="rbs"
                                        onChange={(e) => this.handleVitalInputChange(e, i)} /> mg/dL
                                    </span>
                                    <span className="pv-common pvc-fixed">
                                      {this.state.is_doctor ? "Doctor" : "Nurse"}
                                    </span>
                                  </li>
                                ))}
                                {this.state.patient_vitals.map((data, i) => (
                                  <li className={data.vital_by.toLowerCase()} key={i}>
                                    <span className="pv-title">
                                      <Moment format="D MMM" parse="YYYY-MM-DD HH:mm">
                                        {data.date}
                                      </Moment>{" "}
                                      <br />
                                      <Moment format="h:mm A" parse="YYYY-MM-DD HH:mm">
                                        {data.date}
                                      </Moment>
                                    </span>
                                    <span className="d-flex">
                                      <div className="border text-center mr-1 pv-width  bg-white">
                                        <span className={HANDLE_PATIENT_VITALS_COLORS(data.temperature.temperature_status)}>{data.temperature.temperature_value}</span>
                                      </div>
                                      <span>{TEMPERATURE_TYPE_VALIDATE_FUNCTION(data.temperature.temperature_type)}</span>
                                    </span>
                                    <span className="d-flex">
                                      <div className="border text-center mr-1 pv-width  bg-white">
                                        <span className={HANDLE_PATIENT_VITALS_COLORS(data.spo3.spo_status)}>{data.spo3.spo_value}</span>
                                      </div>
                                      <span>%</span>
                                    </span>
                                    <span className="d-flex">
                                      <div className="border text-center mr-1 pv-width  bg-white">
                                        <span className={HANDLE_PATIENT_VITALS_COLORS(data.bp.bp_systolic_status)}>{data.bp.bp_systolic_value}</span>
                                        /
                                        <span className={HANDLE_PATIENT_VITALS_COLORS(data.bp.bp_diastolic_status)}>{data.bp.bp_diastolic_value}</span>
                                      </div>
                                      <span>mmHg</span>
                                    </span>
                                    <span className="d-flex">
                                      <div className="border text-center mr-1 pv-width  bg-white">
                                        <span className={HANDLE_PATIENT_VITALS_COLORS(data.pulse.pulse_status)}>{data.pulse.pulse_value}</span>
                                      </div>
                                      <span>bpm</span>
                                    </span>
                                    <span className="d-flex mb-1">
                                      <div className="border text-center mr-1 pv-width  bg-white">
                                        <span className={HANDLE_PATIENT_VITALS_COLORS(data.rbs.rbs_status)}>{data.rbs.rbs_value}</span>
                                      </div>
                                      <span>mg/dL</span>
                                    </span>
                                    <span className="pv-common pvc-fixed">
                                      {data.vital_by}
                                    </span>
                                  </li>
                                ))}
                              </ul>) : (pageDataLoaded && this.state.patient_vitals.length === 0 ? "No Patient Vitals" : "")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <Accordion defaultActiveKey={this.state.defaultAccordianOpen}>
                        <Card className={this.state.accordion_open === 0 ? 'active' : ''}>
                          <Accordion.Toggle onClick={() => this.setState({ accordion_open: 0 })} as={Card.Header} variant="link" eventKey="0">
                            <label className="label1 m-0">PROGRESS NOTES: DOCTOR</label>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body className="p-0">
                              {this.state.is_doctor ? (<div className="patient-textwrapper">
                                <textarea
                                  tabIndex="1"
                                  className="text-feild1 patient-vitaltextfield"
                                  rows="5"
                                  cols="20"
                                  placeholder="EX : weakness for 2-3 days"
                                  name="notes_doctor"
                                  value={this.state.notes_doctor}
                                  onChange={this.handleInputChange}
                                  disabled={this.state.appointment_status === 5 ? true : false || !this.state.is_doctor}
                                ></textarea>
                                {/* <i className="mic-icotext"><img src={mic_icotext} alt="MIC ICON" /></i> */}
                                <button className="btn btn-outline-secondary" data-notes="storetestnotes"
                                  onClick={this.handleNotesTestsClick}
                                  disabled={this.state.appointment_status === 5 ? true : false || !this.state.is_doctor}>
                                  SAVE
                                  <span className="loader-span">{LOADER}</span>
                                </button>
                              </div>) : ""}
                              <Card className="patient-lastdatacard">
                                {this.state.notes_doctor_prev && this.state.notes_doctor_prev.count !== 0 ? (<Card.Body className="p-2">
                                  <div className="patient-lastnotest d-flex flex-column">
                                    <div className="pl-common pl-top d-flex justify-content-between">
                                      <div className="plc-common plc-left">{this.state.notes_doctor_prev.notes_by_name}</div>
                                      <div className="plc-common plc-right">
                                        <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                          {this.state.notes_doctor_prev.date.split("T")[0] + this.state.notes_doctor_prev.date.split("T")[1]}
                                        </Moment>
                                        {/* {this.state.notes_doctor_prev.date} */}
                                      </div>
                                    </div>
                                    <pre className="pl-common pl-center">
                                      {this.state.notes_doctor_prev.notes}
                                    </pre>
                                    {this.state.notes_doctor_prev.count > 1 ? (
                                      <div className="pl-common pl-bottom">
                                        <span className="view-all" onClick={() => this.handleProgressNotesDoc('doctor')}>View All</span>
                                      </div>) : ""}
                                  </div>
                                </Card.Body>) : (<Card.Body className="p-2">
                                  <div className="patient-lastnotest d-flex flex-column">
                                    <div className="pl-common pl-center">
                                      No Notes
                                    </div>
                                  </div>
                                </Card.Body>)}
                              </Card>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card className={this.state.accordion_open === 1 ? 'active' : ''}>
                          <Accordion.Toggle onClick={() => this.setState({ accordion_open: 1 })} as={Card.Header} variant="link" eventKey="1">
                            <label className="label1 m-0">MEDICINES &amp; TESTS ADVISED</label>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="p-0">
                              {this.state.is_doctor ? (<div className="patient-textwrapper">
                                <textarea
                                  tabIndex="1"
                                  className="text-feild1 patient-vitaltextfield"
                                  rows="5"
                                  cols="20"
                                  placeholder="EX : weakness for 2-3 days"
                                  name="medicines_tests"
                                  value={this.state.medicines_tests}
                                  onChange={this.handleInputChange}
                                  disabled={this.state.appointment_status === 5 ? true : false || !this.state.is_doctor}
                                ></textarea>
                                {/* <i className="mic-icotext"><img src={mic_icotext} alt="MIC ICON" /></i> */}
                                {/* <button className="btn btn-outline-secondary" data-notes="storetestmedicineadvice"
                                  onClick={this.handleNotesTestsClick}
                                  disabled={this.state.appointment_status === 5 ? true : false || !this.state.is_doctor}>
                                  SAVE
                                  <span className="loader-span">{LOADER}</span>
                                </button> */}
                              </div>) : ""}
                              <Card className="patient-lastdatacard">
                                {this.state.medicines_tests_prev && this.state.medicines_tests_prev.count !== 0 ? (
                                  <Card.Body className="p-2">
                                    <div className="patient-lastnotest d-flex flex-column">
                                      <div className="pl-common pl-top d-flex justify-content-between">
                                        <div className="plc-common plc-left">{this.state.medicines_tests_prev.notes_by_name}</div>
                                        {/* <div className="plc-common plc-right">{this.state.medicines_tests_prev.date}</div> */}
                                        <div className="plc-common plc-right">
                                          <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                            {this.state.medicines_tests_prev.date.split("T")[0] + this.state.medicines_tests_prev.date.split("T")[1]}
                                          </Moment>
                                          {/* {this.state.notes_nurse_prev.date} */}
                                        </div>
                                      </div>
                                      <pre className="pl-common pl-center">
                                        {this.state.medicines_tests_prev.notes}
                                      </pre>
                                      {/* {this.state.medicines_tests_prev.count > 1 ? (
                                      <div className="pl-common pl-bottom">
                                        <span className="view-all" onClick={this.handleProgressNotesDoc}>View All</span>
                                      </div>) : ""} */}
                                    </div>
                                  </Card.Body>) : (<Card.Body className="p-2">
                                    <div className="patient-lastnotest d-flex flex-column">
                                      <div className="pl-common pl-center">
                                        No Medicines &amp; test adviced
                                    </div>
                                    </div>
                                  </Card.Body>)}
                              </Card>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card className={this.state.accordion_open === 2 ? 'active' : ''}>
                          <Accordion.Toggle onClick={() => this.setState({ accordion_open: 2 })} as={Card.Header} variant="link" eventKey="2">
                            <label className="label1 m-0">PROGRESS NOTES: NURSE</label>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="2">
                            <Card.Body className="p-0">
                              {!this.state.is_doctor ? (<div className="patient-textwrapper">
                                <textarea
                                  tabIndex="1"
                                  className="text-feild1 patient-vitaltextfield"
                                  rows="5"
                                  cols="20"
                                  placeholder="EX : weakness for 2-3 days"
                                  name="notes_nurse"
                                  value={this.state.notes_nurse}
                                  onChange={this.handleInputChange}
                                  disabled={this.state.appointment_status === 5 ? true : false || this.state.is_doctor}
                                ></textarea>
                                {/* <i className="mic-icotext"><img src={mic_icotext} alt="MIC ICON" /></i> */}
                                <button className="btn btn-outline-secondary" data-notes="storetestnotes"
                                  onClick={this.handleNotesTestsClick}
                                  disabled={this.state.appointment_status === 5 ? true : false || this.state.is_doctor}>
                                  SAVE
                                  <span className="loader-span">{LOADER}</span>
                                </button>
                              </div>) : ""}
                              <Card className="patient-lastdatacard">
                                {this.state.notes_nurse_prev && this.state.notes_nurse_prev.count !== 0 ? (<Card.Body className="p-2">
                                  <div className="patient-lastnotest d-flex flex-column">
                                    <div className="pl-common pl-top d-flex justify-content-between">
                                      <div className="plc-common plc-left">{this.state.notes_nurse_prev.notes_by_name}</div>
                                      <div className="plc-common plc-right">
                                        <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                          {this.state.notes_nurse_prev.date.split("T")[0] + this.state.notes_nurse_prev.date.split("T")[1]}
                                        </Moment>
                                        {/* {this.state.notes_nurse_prev.date} */}
                                      </div>
                                    </div>
                                    <pre className="pl-common pl-center">
                                      {this.state.notes_nurse_prev.notes}
                                    </pre>
                                    {this.state.notes_nurse_prev.count > 1 ? (
                                      <div className="pl-common pl-bottom">
                                        <span className="view-all" onClick={() => this.handleProgressNotesDoc('nurse')}>View All</span>
                                      </div>) : ""}
                                  </div>
                                </Card.Body>) : (<Card.Body className="p-2">
                                  <div className="patient-lastnotest d-flex flex-column">
                                    <div className="pl-common pl-center">
                                      No Notes
                                    </div>
                                  </div>
                                </Card.Body>)}
                              </Card>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col d-flex align-items-center justify-content-end">
                <div className="col-3"></div>
                <div className="col-9 pr-0">
                  <div className="row">
                    <div className="col-6 d-flex justify-content-between pl-0">
                      <p><span className="recorded-pat-label"></span>Recorded by patient</p>
                      <span>
                        {!this.state.is_adding_vital ? (
                          <button className="btn btn-outline-secondary" onClick={this.handleAddVitalsClick}>ADD VITALS</button>
                        ) : (
                            <span>
                              <button className="btn btn-outline-secondary" onClick={() => this.handleVitalCancelSave('cancel')}>CANCEL</button>
                              <button className="btn btn-outline-secondary ml-3" onClick={() => this.handleVitalCancelSave('save')}>
                                SAVE
                              <span className="loader-span">{LOADER_BLACK}</span>
                              </button>
                            </span>
                          )}
                      </span>
                    </div>
                    <div className="col-6 text-right">
                      {this.state.appointment_status !== 5 ? (
                        <>
                          <button
                            id="completed_consult_btn"
                            className="btn btn-danger complete-btn"
                            onClick={this.handleHomeCareSPCheckBox}
                            disabled={this.state.completeAppointmentBtnValid}
                          >
                            SEND PRESCRIPTION
                        </button>
                        </>) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`col justify-content-center align-self-center mt-5 ${!pageDataLoaded ? "d-flex" : "hidden"}`}
        >
          {LOADER_RED}
        </div>

        <Modal
          isOpen={this.state.modal}
          toggle={this.documentToggle}
          className={this.state.modalClass}
        >
          <ModalHeader toggle={this.documentToggle}></ModalHeader>
          <ModalBody>
            <div className="img-rotate">
              <i className="ir-left" onClick={HANDLEIMAGEROTATE} name="rotate-left"><img className="rotate-left" src={rotateLeft} /></i>
              <i className="ir-right" onClick={HANDLEIMAGEROTATE} name="rotate-right"><img className="rotate-left" src={rotateRight} /></i>
            </div>
            <div className="img-arrows" style={{ 'display': this.state.showArrow ? 'flex' : 'none' }}>
              <i className="ir-left" onClick={this.handleNextPrev} name="image-left"><img className="image-left" src={prevImgIcon} /></i>
              <i className="ir-right" onClick={this.handleNextPrev} name="image-right"><img className="image-left" src={nextImgIcon} /></i>
            </div>
            <div className="object-wrapper">
              <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0"></object>
            </div>
          </ModalBody>
        </Modal>

        <Popup
          className="cancel-pop"
          open={this.state.openhandleCompleteAppointmentButtonPop}
          onClose={() => this.handleCompleteAppointmentButton(false)}
          modal
        >
          <div className="popup">
            <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleCompleteAppointmentButton(false)}>
              &times;
            </div>
            <div className="content mt-5 ml-3 mr-3">
              <p className="doc-popup-text text-center ml-5 mr-5">
                Once the prescription is sent to the patient, no further editing of the prescription will be possible.
              </p>
            </div>
            {QbHelpers.isCallActive || !this.state.QBEndCall ?
              (<div className="pr-3 button text-center">
                <button
                  className="btn btn-outline-dark cancel-btn button-in mr-2"
                  onClick={(e) =>
                    this.completeAppointment(e, this.state.appointment_details.id)
                  }
                >
                  SEND PRESCRIPTION AND CONTINUE WITH CALL
                  <span className="loader-span">{LOADER_BLACK}</span>
                </button>
                <button
                  className="btn btn-danger mt-4 mb-4 button-in"
                  onClick={(e) =>
                    this.endCallAndCompleteAppointment(e,
                      this.state.appointment_details.id
                    )
                  }
                >
                  SEND PRESCRIPTION AND END CALL
                  <span className="loader-span">{LOADER}</span>
                </button>

              </div>)
              : this.state.QBEndCall ?
                (<div className="pr-3 button text-center">
                  <button
                    className="btn btn-danger mt-4 mb-4 button-in"
                    onClick={(e) =>
                      this.completeAppointment(e, this.state.appointment_details.id)
                    }
                  >
                    {" "}
                  SEND PRESCRIPTION TO PATIENT
                  <span className="loader-span">{LOADER}</span>
                  </button>
                </div>) : ""
            }
          </div>
        </Popup>

        <Popup
          className="cancel-pop"
          open={this.state.prescriptionChecklistPop}
          onClose={() => this.setState({ prescriptionChecklistPop: false })}
          modal
        >
          <div className="popup px-3">
            <p className="text-dark font-weight-bold mt-3">Send Prescription</p>
            <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.setState({ prescriptionChecklistPop: false })}>
              &times;
            </div>
            <div className="content">
              <p>Please select the sections you would like to send in the prescription</p>
              {this.state.is_doctor ? (
                <>
                  <div className="p-0">
                    <label className="custom-checkmark-container" htmlFor="defaultCheck1">
                      <input className="form-check-input" type="checkbox" value={this.state.send_notes_for_doctor}
                        id="defaultCheck1" name="send_notes_for_doctor"
                        onClick={this.handlePrescriptionCheckbox} />
                      <span className="checkmark-red-tick "></span>
                      Notes From Patient
                    </label>
                  </div>
                  <div className="p-0">
                    <label className=" custom-checkmark-container" htmlFor="defaultCheck3">
                      <input className="form-check-input" type="checkbox" value={this.state.send_progress_notes_doctor}
                        id="defaultCheck3" name="send_progress_notes_doctor"
                        onClick={this.handlePrescriptionCheckbox} />
                      <span className="checkmark-red-tick "></span>
                      Progress Notes: Doctor
                    </label>
                  </div>
                  <div className="p-0">
                    <label className=" custom-checkmark-container" htmlFor="defaultCheck4">
                      <input className="form-check-input" type="checkbox" value={this.state.send_medicine_test}
                        id="defaultCheck4" name="send_medicine_test"
                        onClick={this.handlePrescriptionCheckbox} />
                      <span className="checkmark-red-tick "></span>
                      Medicines &amp; Tests Advised
                    </label>
                  </div>
                </>
              ) : ""}
              {/* (
                  <>
                    <div className="p-0">
                      <label className=" custom-checkmark-container" htmlFor="defaultCheck4">
                        <input className="form-check-input" type="checkbox" value={this.state.send_progress_notes_nurse}
                          id="defaultCheck4" name="send_progress_notes_nurse"
                          onClick={this.handlePrescriptionCheckbox} />
                        <span className="checkmark-red-tick "></span>
                      Progress Notes: Nurse
                      </label>
                    </div>
                  </>
                )} */}
              <div className="p-0">
                <label className=" custom-checkmark-container" htmlFor="defaultCheck5">
                  {!is_doctor ? (
                    <input className="form-check-input" type="checkbox" value={this.state.send_progress_notes_doctor}
                      id="defaultCheck5" name="send_progress_notes_doctor"
                      onClick={this.handlePrescriptionCheckbox} />
                  ) : (
                      <input className="form-check-input" type="checkbox" value={this.state.send_progress_notes_nurse}
                        id="defaultCheck5" name="send_progress_notes_nurse"
                        onClick={this.handlePrescriptionCheckbox} />
                    )}
                  <span className="checkmark-red-tick "></span>
                      Progress Notes: Nurse
                    </label>
              </div>
              <div className="p-0">
                <label className="custom-checkmark-container" htmlFor="defaultCheck2">
                  <input className="form-check-input" type="checkbox" value={this.state.send_patient_vitals}
                    id="defaultCheck2" name="send_patient_vitals"
                    onClick={this.handlePrescriptionCheckbox} />
                  <span className="checkmark-red-tick "></span>
                      Patient Vitals
                    </label>
              </div>
            </div>
            <div className="text-center my-3">
              <button className="btn btn-primary mt-2 px-4 font-weight-bold"
                onClick={(e) =>
                  this.handleCompleteAppointmentClick(e)
                }>CONFIRM<span className="loader-span">{LOADER}</span></button>
            </div>
          </div>
        </Popup>

        <Popup
          className="homecare-charts-modal"
          open={this.state.chartsPop}
          onClose={() => this.setState({ chartsPop: false })}
          modal
        >
          <div className="popup px-3">
            <h5 className=" mt-4">Vitals Trend</h5>
            <div className="close" onClick={() => this.setState({ chartsPop: false, date_range_graph: false })}>
              &times;
                        </div>
            <div className="content emrfrm">
              <div className="row">
                <div className="col-12">
                  <button className={`btn btn-outline-danger date-range ${date_range_graph ? "active" : ""} `}
                    onClick={() => this.handleDateRangeClick()}>
                    Date Range
                    </button>
                  <nav className="homeCare-charts nav nav-tabs" role="tablist" >
                    <a id="controlled-tab-example-tab-Days" href="#" role="tab"
                      className={`nav-item nav-link ${!date_range_graph && this.state.chartsTab === 0 ? 'active' : ''}`}
                      onClick={(e) => this.handleGraphClick(e, 0)}>DAY</a>
                    <a id="controlled-tab-example-tab-weeks" href="#" role="tab"
                      className={`nav-item nav-link ${!date_range_graph && this.state.chartsTab === 1 ? 'active' : ''}`}
                      onClick={(e) => this.handleGraphClick(e, 1)}>WEEK</a>
                    <a id="controlled-tab-example-tab-months" href="#" role="tab"
                      className={`nav-item nav-link ${!date_range_graph && this.state.chartsTab === 2 ? 'active' : ''}`}
                      onClick={(e) => this.handleGraphClick(e, 2)}>MONTH</a>
                    <a id="controlled-tab-example-tab-years" href="#" role="tab"
                      className={`nav-item nav-link ${!date_range_graph && this.state.chartsTab === 3 ? 'active' : ''}`}
                      onClick={(e) => this.handleGraphClick(e, 3)}>YEAR</a>
                  </nav>
                  <div className="tab-content">
                    <Chart chartData={this.state.chartData} dataLoaded={this.state.chartDataLoaded} is_doctor={this.state.is_doctor}
                      booking_id={this.state.booking_id} type={this.state.chartsTab} date_range={this.state.date_range_graph} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Popup>

        {/* <Popup
          className="cancel-pop"
          open={this.state.openPrescriptionSentPop}
          onClose={() => this.handlePrescriptionSentPop(false)}
          modal
        >
          <div className="popup">
            <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handlePrescriptionSentPop(false)}>
              &times;
            </div>
            <div className="content mt-5 ml-3 mr-3">
              <p className="doc-popup-text text-center ml-5 mr-5">
                Your prescription has been sent to the patient.
              </p>
            </div>
            <div className="pr-3 button text-center">
              <button
                className="btn btn-outline-dark cancel-btn button-in mr-2"
                onClick={() =>
                  this.handlePrescriptionSentPop(false)
                }
              >
                CONTINUE WITH CALL
              </button>
              <button
                className="btn btn-danger mt-4 mb-4 button-in"
                onClick={() =>
                  this.completeAppointment(
                    this.state.appointment_details.id
                  )
                }
              >
                END CALL
              </button>

            </div>
          </div>
        </Popup> */}

        <Popup
          className="cancel-pop"
          open={this.state.openAudioCallPop}
          onClose={() => this.handleAudioCallPop(false)}
          modal
        >
          <div className="popup">
            <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleAudioCallPop(false)}>
              &times;
            </div>
            <div className="content mt-5 ml-3 mr-3">
              <p className="doc-popup-text text-center ml-5 mr-5">
                You will receive a call on your mobile phone, please answer to connect with the patient.
              </p>
            </div>
            <div className="pr-3 button text-center">

              <button
                className="btn btn-danger mt-4 mb-4 button-in mr-2"
                onClick={this.handleAUdioCallApi}
              >
                CONNECT WITH PATIENT
                <span className="loader-span">{LOADER}</span>
              </button>

              <button
                className="btn btn-outline-dark cancel-btn button-in"
                onClick={() => this.handleAudioCallPop(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </Popup>

        <Modal isOpen={this.state.handleCallFeedbackPopup}>
          <ModalHeader
            className="text-center bg-grey">Please rate the quality of your call</ModalHeader>
          <ModalBody className="text-center">
            <div className="beautify_wrapper d-flex flex-column">
              <BeautyStars
                activeColor="#d24350"
                inactiveColor="#F0F0F0"
                value={this.state.doctor_rating}
                onChange={doctor_rating => this.setDoctorRating(doctor_rating)} />
              <span className="mt-3 hidden error-beautystars text-danger">Please rate to Submit!</span>
            </div>
            <br />
            <Input type="textarea" placeholder="Remarks" name="doctor_remarks" value={this.state.doctor_remarks} rows={3}
              onChange={this.handleInputChange} />
          </ModalBody>
          <div className="d-flex text-center modal-header p-0">
            <div className="row justify-content-center">
              <div className="col-md-auto">
                <Button className="button-healthvc button-variant-cancel font-weight-bold"
                  type="button" onClick={this.handleDontRate} >
                  Not now
                </Button>
              </div>
              <div className="col-md-auto">
                <Button className="button-healthvc button-variant-red mb-3 font-weight-bold btn btn-primary"
                  type="button" onClick={this.handleBeautyPopupSubmit} >
                  Submit
                <span className="loader-span">{LOADER}</span>
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <ToastComponent
          successClass={this.state.toastSuccessClass}
          show={this.state.toast}
          message={this.state.toastmsg}
        />
      </>
    );
  }
}

export default withRouter(HcConsult);
