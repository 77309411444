import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import CAL_ICO from "../../assets/images/ic-calendar.png";
import LS_SERVICE from "../../utils/localStorage";
import LINK_ICO from "../../assets/images/link-4-24.png";
import {
  AGENTPORTAL_URL
} from "../../utils/constant";

function SubHeaderComponent(props) {
  // debuggero;
  const handleWorkListNav = () => {
    props.history.push("/patient-list");
  };

  const handleCalNav = () => {
    props.history.push("/my-calendar");
  };

  const openPreviousPrescription = (e) => {
    props.history.push({
      pathname: "/past-visit",
      state: {
        patient_id: e.target.dataset.patientid,
        booking_id: e.target.dataset.bookingid,
        pageTitle: props.pageTitle,
        subPageTitle: props.subPageTitle,
        subPageTitle2: props.subPageTitle2,
      },
    });
  };

  const handleHospitalClick = (e, data) => {
    e.preventDefault();

    props.history.push({
      pathname: "/patient-lists",
      state: {
        hospital_id: data.id,
        is_hospital_homecare: data.is_homecare
      },
    });
  }


  const drPortal = () => {
    window.open(
      AGENTPORTAL_URL + "?is_a_dr=1&mobile_no=" + LS_SERVICE.get("uid"),
      "_blank"
    );
  };


  let subHeader;
  const hospitals = LS_SERVICE.get("hospitals");

  if (props && props.pageTitle === undefined) {
    subHeader = (
      <Container fluid className="container_fluid_custom">
        <Row className="align-items-center">
          <Col>
            <ListGroup horizontal className="subHead-list">
              <ListGroup.Item className="bg-transparent text-white h5 m-0 p-0 mr-3 pr-3 border-left-0 border-top-0 border-bottom-0 d-flex align-items-center">
                Today’s Appointments
              </ListGroup.Item>
              {hospitals.length > 0 ? hospitals.map((e, i) => (
                <button className={`btn btn-allergies btn-allergyworklist px-2 mr-2 ${LS_SERVICE.get('active_hospital').id === e.id ? 'btn-outline-danger btn-worklist text-danger' : ''}`}
                  key={i} onClick={(el) => handleHospitalClick(el, e)}>{e.name}{" "}
                  {e.start_time !== null
                    ? (e.start_time.split("")[0] !== "0"
                      ? e.start_time + " - "
                      : e.start_time.substring(1) + " - ")
                    : ""}
                  {e.end_time !== null
                    ? (e.end_time.split("")[0] !== "0"
                      ? e.end_time
                      : e.end_time.substring(1))
                    : ""}
                </button>
                // <ListGroup.Item key={i} onClick={(el) => handleHospitalClick(el, e)}
                //   className={`bg-transparent h5 m-0 p-0 mr-3 pr-3 border-0 cursor-pointer ${LS_SERVICE.get('active_hospital').id === e.id ? "text-danger" : "text-white"}`}>
                //   {e.name}{" "}
                //   {e.start_time !== null
                //     ? "(" +
                //     (e.start_time.split("")[0] !== "0"
                //       ? e.start_time + " - "
                //       : e.start_time.substring(1) + " - ")
                //     : ""}
                //   {e.end_time !== null
                //     ? (e.end_time.split("")[0] !== "0"
                //       ? e.end_time
                //       : e.end_time.substring(1)) + ")"
                //     : ""}
                // </ListGroup.Item>
              )) : ""}
            </ListGroup>
          </Col>
          <Col>
            <div
              className="h4 m-0 d-flex align-items-center justify-content-end cursor-pointer subHead-list"
            >
              <div className="text-white mb-0 h5 d-flex align-items-center" onClick={handleCalNav}>
                <img
                  src={CAL_ICO}
                  alt="calendar-icon"
                  className="sub-calendar-icon"
                />{" "}
                My Calendar
              </div>

              {/* {Button Appears only for Brich candy hospital which has ID of 11 in production} */}

              {LS_SERVICE.get('active_hospital').id === 11 ? (
                <div className="text-white ml-5 mb-0 h5 d-flex align-items-center" onClick={drPortal}>
                  <img
                    src={LINK_ICO}
                    alt="link-icon"
                    className="sub-calendar-icon"
                  />{" "}
                Doctor Portal
                </div>) : ""
              }

            </div>

          </Col>
        </Row>
      </Container>
    );
  } else {
    subHeader = (
      <Container fluid className="container_fluid_custom">
        <Row className="align-items-center">
          <Col>
            <ListGroup horizontal className="subHead-list">
              <ListGroup.Item className="bg-transparent text-white h5 m-0 p-0 mr-3 border-0">
                {props.pageTitle.toLowerCase()}
              </ListGroup.Item>
              {props.subPageTitle ? (
                <ListGroup.Item className="bg-transparent text-white h5 m-0 p-0 mr-3 pl-3 abc border-right-0 border-top-0 border-bottom-0">
                  {props.subPageTitle}
                </ListGroup.Item>
              ) : null}
              {props.subPageTitle2 ? (
                <ListGroup.Item className="bg-transparent text-white h5 m-0 p-0 mr-3 pl-3 abc border-right-0 border-top-0 border-bottom-0">
                  {props.subPageTitle2}
                </ListGroup.Item>
              ) : null}
            </ListGroup>
          </Col>
          <Col>
            <div className="h4 m-0 d-flex align-items-center justify-content-end">
              {/* {props.E_PrescriptionBtn === "yes" ? (
                <button
                  className="btn btn-ePrescription px-2  mr-2"
                  data-bookingcode={props.booking_code}
                  onClick={openEPrescription}
                >
                  MAX EP
                </button>
              ) : null} */}

              {props.PrescriptionBtn === "yes" ? (
                <button
                  className="btn btn-danger mr-2 btn-workpastpresc btn-worklist"
                  data-patientid={props.patient_id}
                  data-bookingid={props.booking_id}
                  onClick={openPreviousPrescription}
                >
                  {props.btn_name}
                </button>
              ) : null}
              <button
                className="btn btn-outline-danger btn-worklist"
                onClick={handleWorkListNav}
              >
                WORKLIST
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <section style={{ background: "#5c5c5c" }} className="section-subheader">
      {subHeader}
    </section>
  );
}

export default withRouter(SubHeaderComponent);
